const images = {
  logo : require('./logo_seformer-f59ecb37be2dc067b743d7ee0a113952.webp'),
  imgSlide : require('./img-slide-hp.jpg'),
  favicon : require('./favicon.ico'),
  imgSlideWebp : require('./img-slide-hp-6ca02b9b8adb986a503d9ad6e2992964.webp'),
  bgslidesingleFormation : require('./Se-former-Single-formation-Banniere-mobile.jpg'),
  imgTextemploy : require('./7e2481db59ce03de9395ba70daa1bd9d-8c2b9ef0069ed6d0f10f6ea262dbac03.webp'),
  searchSlide : require('./icone-search-slider.png'),
  searchSlideHp : require('./icone-search-slider-hp.png'),
  SingleimgSlide : require('./image-single-image.png'),
  litleSearchIcone : require('./litle-search-icone.png'),
  bgInputSearch : require('./bg-input-search.png'),
  twitter : require('./icone-twitter.png'),
  instagram : require('./icone_instagram.png'),
  linkdin : require('./icone-linkdin.png'),
  facebook : require('./icone-facebook.png'),
  titreLivre : require('./icone-livre.png'),
  logoFormation : require('./logo-cci.jpg'),
  smartPub : require('./img-pub.jpg'),
  imgTextEmploi : require('./img-text-emploi.png'),
  iconeNosFormations : require('./icone-formation.png'),
  logoAdecco : require('./logo-adecco.jpg'),
  LogoAader : require('./Logo-aader.jpg'),
  logoBureauVallee : require('./logo-bureau-vallee.jpg'),
  bgDomainedeformation : require('./bg-domaine-de-formation.jpg'),
  livredomainedeformation : require('./livre-domaine-de-formation.png'),
  bgcolsearch : require('./bg-col-search.png'),
  livreniveaudeformation : require('./livre-niveau-de-formation.png'),
  niveaubacc : require('./niveau-bacc.jpg'),
  niveaubacc2 : require('./niveau-bacc-2.jpg'),
  niveaubep : require('./niveau-bep.jpg'),
  alllevel : require('./all-level.jpg'),
  livrevogue : require('./livre-en-vogue.png'),
  chefdeprojet : require('./chef-de-projet.jpg'),
  controleurdegestion : require('./controleur-de-gestion.jpg'),
  Designer : require('./Designer.jpg'),
  Livremain : require('./Livre-en-main.png'),
  bgpourlescandidats : require('./bg-pour-les-candidats.jpg'),
  bgcentredeformation : require('./bg-centre-de-formation.jpg'),
  iconepourlescandidats : require('./icone-pour-les-candidats.png'),
  iconecentredesformations : require('./icone-centre-des-formations.png'),
  iconeCV : require('./icone_CV.png'),
  iconepublishoffre : require('./icone_publish_offre.png'),
  burgerMenu : require('./icone-menu.png'),
  iconeQuisommesnous : require('./icone-qui-sommes-nous.png'),
  filterIcone  : require('./icone-filter.png'),
  iconeSerchvert  : require('./icone-search-vert.png'),
  slideshowresultatrecherche : require('./image-slideshow-resultat-recherche.jpg'),
  iconeCroix : require('./icone-croix.png'),
  logoTVB : require('./Logotousvabien.jpg'),
  slideSingleFormation : require('./slide-Single-formation-5d90fdb2ca7e4830adac03ab5189aca7.webp'),
  logoregionreunion : require('./logoregionreunion.jpg'),
  FormationSimilaires : require('./icone-formation-similaires.png'),
  btnClose : require('./btn-close.png'),
  iconeNotFound : require('./icone-not-found.png'),
  NotFound : require('./erreur-404-not-found.png'),
  bordermotscles : require('./Mots-cles-hover.png'),
  bgHeaderPopup : require('./bg-header--popup.png'),
  contactbureau : require('./contact-bureau.png'),
  contactfax : require('./contact-fax.png'),
  contactmail : require('./contact-mail.png'),
  contactmobile : require('./contact-mobile.png'),
  btnfilterMobile : require('./btn-filter-mobile.png'),
  iconestagemobile : require('./icone-stagemobile.jpg'),
  iconeusermobile : require('./icone-user-mobile.jpg'),
  iconeformationmobile : require('./icone-formation-mobile.jpg'),
  iconeemploimobile : require('./icone-emploi-mobile.jpg'),
  iconelogoutmobile : require('./icone-logout-mobile.jpg'),
  iconedomainedeformation : require('./icone-mobile-title-domainedeformation.png'),
  iconeFermer : require('./icone-close-menu-mobile.png'),
  iconeClosebtn : require('./close-button.png'),
  imagetitlecontact : require('./image-contact.png'),
  iconeounoustrouver : require('./icone-nous-trouver.png'),
  iconeenvoiemail : require('./icone-envoie-mail-contact.png'),
  iconeenvoiemailblanc : require('./icone-envoie-mail-contact-blanc.png'),
  tvbPopup : require('./logo-tvb-Se-former-Popup.png'),
  logoSeformerPopup : require('./Logo-Se-formerSingle-formationPopup.png'),
  flechebackpopup : require('./fleche-revenir-Popup.png'),
  backsingleformation : require('./Fleche-back-single-formation.jpg'),
  iconechercheblanc : require('./icone-cherche-blanc.png'),
  paginationprec : require('./nav-pagination-next.png'),
  paginationnext : require('./nav-pagination-prec.png'),
  domaineForm : require('./bg-full-domaine-de-formation.jpg'),
  breadcrumbsSeparator : require('./arrow-separator-breadcrumbs.png'),
  checkpopup : require('./check-popup.png'),
  iconnotsend : require('./error.png'),
  noimage : require('./no-image-seformer.png'),
  bgliste: require('./bgliste-besoin.png'),
};

export default images;