import React from "react"
import {Helmet} from "react-helmet"
import clip from "text-clipper";
import images from '../../assets/images';

const SiteMetadata = ({metas}) => {
    return (
        <Helmet defer={false}>
            <title>{metas?.title}</title>
            <meta name='title' content={metas?.metatitle}/>
            <meta name="description" content={clip(metas?.description, 160)}/>
            <meta name="keywords" content={metas?.keywords}/>
            <link rel="shortcut icon" href={images.favicon}/>
        </Helmet>
    )
};

export default SiteMetadata
