import React from 'react';
import images from '../../assets/images';
import './Popup.css';

function Popup(props) {
  return (
    <>
    <div className="Popup footer-popup" id="Modal" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header" style={{backgroundImage: `url('${images.bgHeaderPopup}')` }} >
            <h5 className="modal-title" id="exampleModalLabel">Nous contacter</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.togglethePopUp}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body pb-4">
            <h3 className="Nomdanspopup">Mme Aurélie MARIOTTI</h3>
            <ul className="contact-infos">
              <li className="contact-mail" style={{backgroundImage: `url('${images.contactmail}')` }}>Mail : <span><a href="mailto:contact@seformer.re">contact@seformer.re</a></span></li>
              <li className="contact-bureau" style={{backgroundImage: `url('${images.contactbureau}')` }}>Bureau : <span>0262 48 28 44</span></li>
              <li className="contact-mobile" style={{backgroundImage: `url('${images.contactmobile}')` }}>Mobile : <span>0692 43 91 01</span></li>
              {/*<li className="contact-fax" style={{backgroundImage: `url('${images.contactfax}')` }}>Fax : <span>0262 48 28 17</span></li>*/}
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="Fondpopup"></div>
      </>
  );
}

export default Popup;